import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AxiosError as AxiosErrorType,
  AxiosInstance,
  AxiosResponse,
} from "axios";
import { ElectricitySupplyServiceInvoiceDetail } from "../../../models/invoicesModals/responses/ElectricitySupplyServiceInvoiceDetail.model";
import { EnerbitServicesInvoiceDetail } from "../../../models/invoicesModals/responses/EnerbitServicesInvoiceDetail.model";
import { FixedEnerbitElectricitySupplyInvoiceDetail } from "../../../models/invoicesModals/responses/FixedEnerbitElectricitySupplyInvoiceDetail.model";
import { ThirdPartyServicesDetail } from "../../../models/invoicesModals/responses/ThirdPartyServicesDetail.model";

type MyAxiosError = AxiosErrorType;

//Obtener billing (factura) de un electricity supply service
export const getInvoiceDetailElectricitySupplyService = createAsyncThunk<
  ElectricitySupplyServiceInvoiceDetail,
  string,
  { rejectValue: MyAxiosError }
>(
  "getInvoiceDetailElectricitySupplyService",
  async (invoiceId: string, thunkAPI) => {
    try {
      const response: AxiosResponse<ElectricitySupplyServiceInvoiceDetail> =
        await (api as AxiosInstance).get(
          `/electricity-supply-service/billing/electricity-supply/${invoiceId}`
        );
      return response.data;
    } catch (error: any) {
      console.error(error);
      const axiosError: MyAxiosError = error as MyAxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

//Descargar billing (factura) de un servicio
export const downloadInvoice = createAsyncThunk(
  "billing/downloadInvoice",
  async (invoiceId: string) => {
    let response = await api.get(`/billing/invoices/${invoiceId}/document/`, {
      params: {},
      responseType: "arraybuffer",
    });
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `${invoiceId}.pdf`;
    link.click();
    URL.revokeObjectURL(fileURL);
  }
);

//Obtener billing (factura) de un fixed enerbit electricity supply
export const getInvoiceDetailFixedEnerbitElectricitySupply = createAsyncThunk<
  FixedEnerbitElectricitySupplyInvoiceDetail,
  string,
  { rejectValue: MyAxiosError }
>(
  "getInvoiceFixedEnerbitElectricitySupply",
  async (invoiceId: string, thunkAPI) => {
    try {
      const response: AxiosResponse<FixedEnerbitElectricitySupplyInvoiceDetail> =
        await (api as AxiosInstance).get(
          `/electricity-supply-service/billing/fixed-enerbit-electricity-supply/${invoiceId}`
        );
      return response.data;
    } catch (error: any) {
      console.error(error);
      const axiosError: MyAxiosError = error as MyAxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

//Obtener billing (factura) de un enerbit electricity supply
export const getInvoiceDetailEnerbitElectricitySupply = createAsyncThunk<
  EnerbitServicesInvoiceDetail,
  string,
  { rejectValue: MyAxiosError }
>(
  "getInvoiceDetailEnerbitElectricitySupply",
  async (invoiceId: string, thunkAPI) => {
    try {
      const response: AxiosResponse<EnerbitServicesInvoiceDetail> = await (
        api as AxiosInstance
      ).get(
        `/electricity-supply-service/billing/enerbit-electricity-supply/invoice/${invoiceId}`
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      const axiosError: MyAxiosError = error as MyAxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

//Obtener billing (factura) de los servicios de terceros
export const getInvoiceDetailThirdPartyServices = createAsyncThunk<
  ThirdPartyServicesDetail,
  string,
  { rejectValue: MyAxiosError }
>("getInvoiceDetailThirdPartyServices", async (invoiceId: string, thunkAPI) => {
  try {
    const response: AxiosResponse<ThirdPartyServicesDetail> = await (
      api as AxiosInstance
    ).get(
      `/electricity-supply-service/billing/third-party-services/${invoiceId}`
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    const axiosError: MyAxiosError = error as MyAxiosError;
    return thunkAPI.rejectWithValue(axiosError);
  }
});
