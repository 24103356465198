import { Box, formatterPeso, Typography } from "@enerbit/base";
import { SettlementCardRowProps } from "../../../../../../../models/props/SettlementCardRow";
import { ContainerCardRowTotalFooter } from "../../style-components-cards/CardComponent";

export const SettlementCardRowTotalFooter = ({
  title,
  fontWeight = 700,
  value1,
  value2,
}: SettlementCardRowProps) => {
  return (
    <ContainerCardRowTotalFooter sx={{ padding: "10px 0px 10px 0px" }}>
      <Box
        style={{ width: "40%", padding: "0px 1rem" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight }}>{title}</Typography>
      </Box>
      <Box
        style={{ width: "30%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {value1 ? (
          <Typography sx={{ fontWeight }}>
            {value1 || value1 === 0
              ? formatterPeso.format(value1 as number)
              : ""}
          </Typography>
        ) : (
          <Box sx={{ pl: "5rem" }}>No hay valor disponible</Box>
        )}
      </Box>
      <Box
        style={{ width: "30%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {value2 ? (
          <Typography sx={{ fontWeight }}>
            {value2 || value2 === 0
              ? formatterPeso.format(value2 as number)
              : ""}
          </Typography>
        ) : (
          <Box sx={{ pl: "5rem" }}>No hay valor disponible</Box>
        )}
      </Box>
    </ContainerCardRowTotalFooter>
  );
};
