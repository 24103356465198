import {
  Box,
  Typography,
  formatTwoDecimals,
  formatterPeso,
  theme,
} from "@enerbit/base";

import { EnerbitServicesInvoiceDetail } from "../../../../../../models/invoicesModals/responses/EnerbitServicesInvoiceDetail.model";
import { SettlementCardRowTotalHeader } from "../settlement-cards/energy-regulate-card";
import {
  CardHeader,
  CardsContainer,
} from "../style-components-cards/CardComponent";
import { CardComponent } from "./utils/CardComponent";

export const EnerbitServicesContainer = ({
  enerbitServicesBillingDetail,
}: {
  enerbitServicesBillingDetail: EnerbitServicesInvoiceDetail;
}) => (
  <Box>
    {enerbitServicesBillingDetail.enerbit_service_agreement && (
      <Box sx={{ mb: "2rem" }}>
        <CardHeader>
          <SettlementCardRowTotalHeader
            title="Total servicios enerBit"
            color="white"
            fontWeight={700}
            value1={enerbitServicesBillingDetail.k_active_energy?.amount}
          />
        </CardHeader>
        <Box className="Container-enerbit-services">
          <CardsContainer
            sx={{
              padding: "1rem 1rem",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: "700", fontSize: "35px" }}
              color={theme.palette.primary.main}
            >
              {"("}
            </Typography>
            <CardComponent
              title="G+C pactado"
              color={theme.palette.primary.main}
              value1={formatterPeso.format(
                enerbitServicesBillingDetail.k_active_energy
                  ?.agreed_price as number
              )}
            />

            <Typography
              sx={{ fontWeight: "700", fontSize: "35px" }}
              color={theme.palette.primary.main}
            >
              -
            </Typography>
            <CardComponent
              title="G+C aplicado"
              color={theme.palette.primary.main}
              value1={formatterPeso.format(
                enerbitServicesBillingDetail.k_active_energy?.gc as number
              )}
            />

            <Typography
              sx={{ fontWeight: "700", fontSize: "35px" }}
              color={theme.palette.primary.main}
            >
              {")"}
            </Typography>

            <Typography
              sx={{ fontWeight: "700", fontSize: "35px" }}
              color={theme.palette.primary.main}
            >
              x
            </Typography>
            <CardComponent
              title="Consumo"
              color={theme.palette.primary.main}
              value1={formatTwoDecimals.format(
                enerbitServicesBillingDetail?.k_active_energy
                  ?.quantity as number
              )}
              suffix=" kWh"
            />

            <Typography
              sx={{ fontWeight: "700", fontSize: "35px" }}
              color={theme.palette.primary.main}
            >
              =
            </Typography>
            <CardComponent
              color={theme.palette.primary.main}
              value1={formatterPeso.format(
                enerbitServicesBillingDetail?.k_active_energy?.amount as number
              )}
            />
          </CardsContainer>
        </Box>
      </Box>
    )}
  </Box>
);
