import { Box, Typography } from "@enerbit/base";
import { SettlementCardRowProps } from "../../../../../../../models/props/SettlementCardRow";
import { ContainerCardRowConceptsTitle } from "../../style-components-cards/CardComponent";

export const SettlementCardRowConceptsTitle = ({
  title,
  fontWeight = 700,
  value2,
  color,
}: SettlementCardRowProps) => {
  return (
    <ContainerCardRowConceptsTitle>
      <Box
        style={{ width: "40%", gap: "0.5rem", padding: "0 1rem" }}
        sx={{ display: "flex" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight, color }}>
          Detalle
        </Typography>
      </Box>
      <Box
        style={{ width: "30%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight, color }}>
          Penalización reactiva exportada
        </Typography>
      </Box>
      <Box
        style={{ width: "30%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight, color }}>
          Penalización reactiva importada
        </Typography>
      </Box>
    </ContainerCardRowConceptsTitle>
  );
};
