import { Typography, styled } from "@enerbit/base";

export const CardContainer = styled("div")`
  border-radius: 1rem;
  margin-bottom: 20px;
`;

export const CardBody = styled("div")`
  background: white;
  border-left: 1px solid #e4e7eb;
  border-right: 1px solid #e4e7eb;
`;

export const CardBodyTitle = styled("div")`
  padding: 10px 0px 10px 0px;
  background: white;
  color: var(--color-primary);
  border-bottom: 2px solid #e4e7eb;
`;

export const CardHeader = styled("div")`
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  min-height: 49px;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid #e4e7eb;
  background: var(--color-primary);
  color: white;
`;
export const CardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  min-height: 2rem;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: var(--color-neutral100);
  color: var(--color-neutral700);
`;

export const CardsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 1.3rem;
  margin: 0 1rem;
`;
export const Card = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: #f2f4f7;
  padding: 10px 20px;
  border-radius: 16px;
`;

export const TypographyValue = styled(Typography)`
  color: primary;
  font-weight: 700;
  font-size: 16px;
`;

export const TypographyLabel = styled(Typography)`
  color: primary;
  font-size: 16px;
`;

export const ContainerCardRowTotalHeader = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
export const ContainerTitleAndIcon = styled("div")`
  display: flex;
  gap: 0.5rem;
  width: 60%;
  align-items: center;

  &:hover {
    color: var(--primary);
  }
`;

export const Icon = styled("div")`
  display: flex;
  justify-content: center;
  padding: 0.3rem 0rem;
  min-width: 30px;
  border-radius: 15%;
  text-align: center;
`;

export const ContainerCardRowTotalFooter = styled("div")`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  justify-content: space-between;
  color: var(--color-neutral700);
  background: var(--color-neutral100);
  border-radius: 0 0 1rem 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
export const ContainerTitle = styled("div")`
  display: flex;
  gap: 0.5rem;
  width: 40%;
  padding: 0 1rem;
  &:hover {
    color: var(--primary);
  }
`;
export const ContainerRowConcepts = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #e4e7eb;
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
export const ContainerCardRowConceptsTitle = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #a3a9b6;
  color: var(--color-primary);
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;

export const ContainerCardRowConcepts = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #e4e7eb;
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
