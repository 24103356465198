import {
  Component,
  Invoice,
} from "../models/modals/settlement/ElectricitySupplyService";

export enum InvoiceComponentName {
  activeEnergyImported = "active energy imported",
  contributionByActiveEnergyImported = "contribution by active energy imported",
  outageCompensationByFrequency = "outage compensation by frequency",
  outageCompensationByDuration = "outage compensation by duration",
  penaltyReactiveEnergyExportedAdjustment = "penalty reactive energy exported",
  penaltyReactiveEnergyImportedAdjustment = "penalty reactive energy imported",
}

export const toggleInvoiceComponentPenaltyReactiveEnergyImported = (
  invoice: Invoice
) => {
  if (invoice.components) {
    for (const component of invoice.components) {
      // Si el componente tiene la clave 'unica', lo devolvemos
      if (
        component.name ===
        InvoiceComponentName.penaltyReactiveEnergyImportedAdjustment
      ) {
        return true;
      }
    }
  }
  return false;
};

export const toggleInvoiceComponentPenaltyReactiveEnergyExported = (
  invoice: Invoice
) => {
  if (invoice.components) {
    for (const component of invoice.components) {
      // Si el componente tiene la clave 'unica', lo devolvemos
      if (
        component.name ===
        InvoiceComponentName.penaltyReactiveEnergyExportedAdjustment
      ) {
        return true;
      }
    }
  }
  return false;
};

export const getInvoiceComponentActiveEnergyImported = (invoice: Invoice) => {
  if (invoice.components) {
    for (const component of invoice.components) {
      // Si el componente tiene la clave 'unica', lo devolvemos
      if (component.name === InvoiceComponentName.activeEnergyImported) {
        return component;
      }
    }
  }
  return null;
};

export const getInvoiceComponentContributionByActiveEnergyImported = (
  invoice: Invoice
) => {
  if (invoice.components) {
    for (const component of invoice?.components) {
      // Si el componente tiene la clave 'unica', lo devolvemos
      if (
        component.name ===
        InvoiceComponentName.contributionByActiveEnergyImported
      ) {
        return component;
      }
    }
  }
  return null;
};

export const getInvoiceComponentOutageCompensationByDuration = (
  component: Component
) => {
  if (component) {
    // Si el componente tiene la clave 'unica', lo devolvemos
    if (component.name === InvoiceComponentName.outageCompensationByDuration) {
      return component;
    }
  }
  return null;
};

export const getInvoiceComponentOutageCompensationByFrequency = (
  component: Component
) => {
  if (component) {
    // Si el componente tiene la clave 'unica', lo devolvemos
    if (component.name === InvoiceComponentName.outageCompensationByFrequency) {
      return component;
    }
  }
  return null;
};
