import { Box, formatterPeso, Typography } from "@enerbit/base";
import { SettlementCardRowProps } from "../../../../../../../models/props/SettlementCardRow";
import {
  ContainerCardRowTotalFooter,
  ContainerTitle,
} from "../../style-components-cards/CardComponent";

export const SettlementCardRowTotalFooter = ({
  title,
  fontWeight = 700,
  value1,
  value2,
}: SettlementCardRowProps) => {
  return (
    <ContainerCardRowTotalFooter>
      <ContainerTitle>
        <Typography sx={{ fontSize: "14px", fontWeight }}>{title}</Typography>
      </ContainerTitle>
      <Box
        style={{ width: "23.1%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontWeight }}>
          {value1 || value1 == 0
            ? formatterPeso.format(value1 as number)
            : "No hay valor disponible"}
        </Typography>
      </Box>
    </ContainerCardRowTotalFooter>
  );
};
