import {
  Typography,
  capitalizeFirstLetter,
  enerbitColors,
} from "@enerbit/base";
import moment from "moment";

export const DetailSettlementLeft = ({
  issuedAt,
  dateStart,
  dateEnd,
}: {
  issuedAt?: Date;
  dateStart?: Date;
  dateEnd?: Date;
}) => (
  <>
    <Typography>
      <span style={{ color: enerbitColors.primary.main, fontWeight: "700" }}>
        Fecha emisión factura:{" "}
      </span>
      <span style={{ color: enerbitColors.primary.main }}>
        {issuedAt
          ? moment(issuedAt).format("DD") +
            " de " +
            `${capitalizeFirstLetter(moment(issuedAt).format("MMMM"))}` +
            " del " +
            moment(issuedAt).format("YYYY")
          : "No disponible"}
      </span>
    </Typography>
    {dateStart || dateEnd ? (
      <>
        <Typography>
          <span
            style={{ color: enerbitColors.primary.main, fontWeight: "700" }}
          >
            Consumos:{" "}
          </span>
          <span style={{ color: enerbitColors.primary.main }}>
            {dateStart
              ? `${moment(dateStart).format("DD")} ${capitalizeFirstLetter(
                  moment(dateStart).format("MMMM")
                )} de ${moment(dateStart).format("YYYY")}`
              : "Fecha de inicio no disponible"}
            {dateEnd
              ? ` al ${moment(dateEnd).format("DD")} ${capitalizeFirstLetter(
                  moment(dateEnd).format("MMMM")
                )} de ${moment(dateEnd).format("YYYY")}`
              : " Fecha de fin no disponible"}
          </span>
        </Typography>
      </>
    ) : null}
  </>
);
