import { Box, Typography, formatterPeso } from "@enerbit/base";
import { formatDecimal } from "../../../../../../../helpers/formatDecimals";
import { SettlementCardRowConceptsProps } from "../../../../../../../models/props/SettlementCardRow";
import { createTitleWithAllBoldLetters } from "../../../../../../../utils/titleUtils";
import { ContainerCardRowConcepts } from "../../style-components-cards/CardComponent";

export const SettlementCardRowConcepts = ({
  title,
  fontWeight = 400,
  value1,
  value2,
  value3,
  value4,
}: SettlementCardRowConceptsProps) => {
  return (
    <ContainerCardRowConcepts>
      <Box
        style={{ width: "40%", padding: "0px 1rem" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight }}>
          {title ? title : <Box>No hay valor disponible</Box>}
        </Typography>
      </Box>
      <Box
        style={{ width: "30%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {value1 !== null && value1 !== undefined ? (
          <Typography sx={{ fontWeight }}>
            {formatDecimal.format(value1 as number)}
          </Typography>
        ) : value3 !== null && value3 !== undefined ? (
          <Typography sx={{ fontWeight }}>
            {formatterPeso.format(value3 as number)}{" "}
            {createTitleWithAllBoldLetters("/kVArh")}
          </Typography>
        ) : (
          <Box>No hay valor disponible</Box>
        )}
      </Box>
      <Box
        style={{ width: "30%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {value2 !== null && value2 !== undefined ? (
          <Typography sx={{ fontWeight }}>
            {formatDecimal.format(value2 as number)}
          </Typography>
        ) : value4 !== null && value4 !== undefined ? (
          <Typography sx={{ fontWeight }}>
            {formatterPeso.format(value4 as number)}{" "}
            {createTitleWithAllBoldLetters("/kVArh")}
          </Typography>
        ) : (
          <Box>No hay valor disponible</Box>
        )}
      </Box>
    </ContainerCardRowConcepts>
  );
};
