import {
  Box,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  styled,
} from "@enerbit/base";

import { useState } from "react";
import { SettlementCardsProps } from "../../../../../../../models/props/SettlementCards";
import {
  toggleInvoiceComponentPenaltyReactiveEnergyExported,
  toggleInvoiceComponentPenaltyReactiveEnergyImported,
} from "../../../../../../../utils/billingUtils";
import { createTitleWithBoldLetter } from "../../../../../../../utils/titleUtils";
import {
  CardBody,
  CardContainer,
  CardHeader,
} from "../../style-components-cards/CardComponent";
import { SettlementCardRowConcepts } from "./SettlementCardRowConcepts";
import { SettlementCardRowConceptsTitle } from "./SettlementCardRowConceptsTitle";
import { SettlementCardRowTotalFooter } from "./SettlementCardRowTotalFooter";
import { SettlementCardRowTotalHeader } from "./SettlementCardRowTotalHeader";

const SettlementEnergyReactivePenaltyCard = ({
  invoice,
  settlement,
}: SettlementCardsProps) => {
  const [displayTariff, setDisplayTariff] = useState(true);

  const mCounterExportedReactivePenalty =
    settlement?.components?.capacitive_reactive_power_settlement?.m_counter?.m;
  const mCounterImportedReactivePenalty =
    settlement?.components?.inductive_reactive_power_settlement?.m_counter?.m;

  const penalizedAmountExportedReactive =
    settlement?.components?.capacitive_reactive_power_settlement?.quantity;

  const penalizedAmountImportedReactive =
    settlement?.components?.inductive_reactive_power_settlement?.quantity;

  const d_value = invoice?.details?.electricity_tariff?.components?.d_component;
  const totalPenalizationEnergyReactiveExported =
    settlement?.components?.capacitive_reactive_power_settlement?.amount;
  const totalPenalizationEnergyReactiveImported =
    settlement?.components?.inductive_reactive_power_settlement?.amount;
  const totalHeaderCardPenalizationEnergyReactive =
    (totalPenalizationEnergyReactiveExported || 0) +
    (totalPenalizationEnergyReactiveImported || 0);
  return (
    <Box>
      {true &&
        (toggleInvoiceComponentPenaltyReactiveEnergyImported(invoice) ||
          toggleInvoiceComponentPenaltyReactiveEnergyExported(invoice)) && (
          <>
            <CardContainer>
              <CardHeader
                sx={{
                  background: "#D9D2E6",
                }}
              >
                <SettlementCardRowTotalHeader
                  backgroundColor={"var(--color-primary)"}
                  title={"Detalle penalización energía reactiva"}
                  fontWeight={700}
                  color={"var(--color-primary)"}
                  value1={totalHeaderCardPenalizationEnergyReactive.toFixed(2)}
                />
                <IconButton
                  size="small"
                  sx={{ color: "white" }}
                  onClick={() => setDisplayTariff(!displayTariff)}
                >
                  {displayTariff ? (
                    <KeyboardArrowDownIcon
                      sx={{ color: "var(--color-primary)" }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      sx={{ color: "var(--color-primary)" }}
                    />
                  )}
                </IconButton>
              </CardHeader>

              {displayTariff && (
                <>
                  {" "}
                  <CardBody>
                    <SettlementCardRowConceptsTitle
                      title={"Detalle"}
                      color={"var(--color-primary)"}
                    />
                    <SettlementCardRowConcepts
                      title={"Multiplicador (M)"}
                      value1={mCounterExportedReactivePenalty}
                      value2={mCounterImportedReactivePenalty}
                    />
                    <SettlementCardRowConcepts
                      title={"Cantidad penalizable (kVArh)"}
                      value1={penalizedAmountExportedReactive}
                      value2={penalizedAmountImportedReactive}
                    />
                    <SettlementCardRowConcepts
                      title={createTitleWithBoldLetter(
                        "Componente D ($/kVArh)",
                        "D"
                      )}
                      value3={d_value}
                      value4={d_value}
                    />
                  </CardBody>
                  <CardFooter>
                    <SettlementCardRowTotalFooter
                      title={"Total"}
                      fontWeight={700}
                      value1={totalPenalizationEnergyReactiveExported?.toFixed(
                        2
                      )}
                      value2={totalPenalizationEnergyReactiveImported?.toFixed(
                        2
                      )}
                    />
                  </CardFooter>
                </>
              )}
            </CardContainer>
          </>
        )}
    </Box>
  );
};
export default SettlementEnergyReactivePenaltyCard;

export const CardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  min-height: 2rem;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: transparent;
  color: var(--color-neutral700);
`;
