import { Box } from "@enerbit/base";
import { ElectricitySupplyServiceInvoiceDetail } from "../../../../../../models/invoicesModals/responses/ElectricitySupplyServiceInvoiceDetail.model";
import SettlementEnergyActiveRateCard from "../settlement-cards/energy-active-rate-and-unit-cost-card/SettlementEnergyActiveRateCard";
import SettlementEnergyReactivePenaltyCard from "../settlement-cards/energy-reactive-penalty-card/SettlementEnergyReactivePenaltyCard";
import SettlementEnergyRegulateCard from "../settlement-cards/energy-regulate-card/SettlementEnergyRegulateCard";
import SettlementUsagesGraphsCard from "../settlement-cards/usages-graphs-card/SettlementUsagesTableCard";

export const ElectricitySupplyServiceContainer = ({
  invoice,
  settlement,
}: ElectricitySupplyServiceInvoiceDetail) => {
  return (
    <>
      <Box
        display="flex"
        gap="2rem"
        sx={{
          "@media (max-width: 1020px)": {
            flexDirection: "column",
          },
        }}
      >
        {invoice && (
          <Box width="100%">
            <SettlementEnergyRegulateCard invoice={invoice} />
            <SettlementEnergyReactivePenaltyCard
              invoice={invoice}
              settlement={settlement}
            />
            <SettlementUsagesGraphsCard
              settlement={settlement}
              invoice={invoice}
            />
            <SettlementEnergyActiveRateCard invoice={invoice} />
          </Box>
        )}
      </Box>
    </>
  );
};
