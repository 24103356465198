import { Box, formatterPeso, styled, Typography } from "@enerbit/base";
import {
  AntennaTransmission,
  Commercialization,
  Distribution,
  Losses,
  PowerStation,
  Restrictions,
  SolarField,
} from "../../../../../../../assets/modals/components-energy";
import { SettlementCardRowEnergyActiveRateCardProps } from "../../../../../../../models/props/SettlementCardRow";

export const SettlementCardRowTotalFooter = ({
  fontWeight = 700,
  value2,
}: SettlementCardRowEnergyActiveRateCardProps) => {
  const electricityTariff = value2?.details?.electricity_tariff;

  let g_value, c_value, t_value, d_value, pr_value, r_value, unit_cost;

  if (electricityTariff) {
    const { components } = electricityTariff;
    unit_cost = electricityTariff.unit_cost;

    if (components) {
      g_value = components.g_component;
      c_value = components.c_component;
      t_value = components.t_component;
      d_value = components.d_component;
      pr_value = components.pr_component;
      r_value = components.r_component;
    }
  }
  return electricityTariff ? (
    <>
      <ContainerComponents>
        <ContainerComponent>
          <NameAndIcon>
            <Icon>
              <img src={SolarField} alt="icon-web" />
            </Icon>
          </NameAndIcon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight,
              color: "var(--color-neutral700)",
              marginBottom: "0.5rem",
            }}
          >
            Generación
          </Typography>
          <Typography sx={{ fontWeight: "400" }}>
            {g_value || g_value == 0
              ? formatterPeso.format(g_value as number)
              : "No hay valor disponible"}
          </Typography>
        </ContainerComponent>
        <ContainerComponent>
          <NameAndIcon>
            <Icon>
              <img src={AntennaTransmission} alt="icon-web" />
            </Icon>
          </NameAndIcon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight,
              color: "var(--color-neutral700)",
              marginBottom: "0.5rem",
            }}
          >
            Transmisión
          </Typography>
          <Typography sx={{ fontWeight: "400" }}>
            {t_value || t_value == 0
              ? formatterPeso.format(t_value as number)
              : "No hay valor disponible"}
          </Typography>
        </ContainerComponent>
        <ContainerComponent>
          <NameAndIcon>
            <Icon>
              <img src={Distribution} alt="icon-web" />
            </Icon>
          </NameAndIcon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight,
              color: "var(--color-neutral700)",
              marginBottom: "0.5rem",
            }}
          >
            Distribución
          </Typography>
          <Typography sx={{ fontWeight: "400" }}>
            {d_value || d_value == 0
              ? formatterPeso.format(d_value as number)
              : "No hay valor disponible"}
          </Typography>
        </ContainerComponent>
        <ContainerComponent>
          <NameAndIcon>
            <Icon>
              <img src={Commercialization} alt="icon-web" />
            </Icon>
          </NameAndIcon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight,
              color: "var(--color-neutral700)",
              marginBottom: "0.5rem",
            }}
          >
            Comercialización
          </Typography>
          <Typography sx={{ fontWeight: "400" }}>
            {c_value || c_value == 0
              ? formatterPeso.format(c_value as number)
              : "No hay valor disponible"}
          </Typography>
        </ContainerComponent>
        <ContainerComponent>
          <NameAndIcon>
            <Icon>
              <img src={Losses} alt="icon-web" />
            </Icon>
          </NameAndIcon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight,
              color: "var(--color-neutral700)",
              marginBottom: "0.5rem",
            }}
          >
            Pérdidas
          </Typography>
          <Typography sx={{ fontWeight: "400" }}>
            {pr_value || pr_value == 0
              ? formatterPeso.format(pr_value as number)
              : "No hay valor disponible"}
          </Typography>
        </ContainerComponent>
        <ContainerComponent>
          <NameAndIcon>
            <Icon>
              <img src={Restrictions} alt="icon-web" />
            </Icon>
          </NameAndIcon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight,
              color: "var(--color-neutral700)",
              marginBottom: "0.5rem",
            }}
          >
            Restricciones
          </Typography>
          <Typography sx={{ fontWeight: "400" }}>
            {r_value || r_value == 0
              ? formatterPeso.format(r_value as number)
              : "No hay valor disponible"}
          </Typography>
        </ContainerComponent>
        <ContainerComponent>
          <NameAndIcon>
            <Icon sx={{ background: "var(--color-primary)" }}>
              <img src={PowerStation} alt="icon-web" />
            </Icon>
          </NameAndIcon>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight,
              color: "var(--color-primary)",
              marginBottom: "0.5rem",
            }}
          >
            Costo unitario
          </Typography>
          <Typography sx={{ fontWeight: "400", color: "var(--color-primary)" }}>
            {unit_cost || unit_cost == 0
              ? formatterPeso.format(unit_cost as number)
              : "No hay valor disponible"}
          </Typography>
        </ContainerComponent>
      </ContainerComponents>
    </>
  ) : (
    <Box sx={{ pl: "5rem" }}>No hay valor disponible</Box>
  );
};

const ContainerComponents = styled("div")`
  display: flex;
  width: 100%;
  padding: 0.625rem 0;
  justify-content: space-between;
  color: var(--color-neutral700);
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
const ContainerComponent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.625rem 0;
  justify-content: space-between;
  color: var(--color-neutral700);
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
const NameAndIcon = styled("div")`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  &:hover {
    color: var(--primary);
  }
`;

const Icon = styled("div")`
  display: flex;
  background: #eeebf4;
  width: 100%;
  padding: 1rem;
  border-radius: 50%;
  justify-content: center;
  min-width: 30px;
  text-align: center;
`;
