import { capitalizeFirstLetter, enerbitColors } from "@enerbit/base";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { formatDecimal } from "../../helpers/formatDecimals";
import { PreviousUsage } from "../../models/invoicesModals/responses/ElectricitySupplyServiceInvoiceDetail.model";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LogarithmicScale,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface BarChartProps {
  sortedUsagesByMonth: PreviousUsage[];
}

const options: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
      color: "white",
      anchor: "center",
      align: "center",
      formatter: (value) => {
        return !isNaN(value) && value !== 0
          ? formatDecimal.format(value)
          : null;
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += formatDecimal.format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },

  scales: {
    x: {
      ticks: {
        color: enerbitColors.tertiary[900],
        font: {
          weight: "normal",
        },
      },
      title: {
        font: { family: "Helvetica Neue LT Std", weight: "bold" },
        color: "#222222",
      },
    },
    y: {
      ticks: {
        callback: function (value) {
          if (typeof value === "number") {
            return formatDecimal.format(value);
          }
          return value;
        },
      },
      title: {
        display: true,
        text: "kWh",
        font: { weight: "bold" },
        color: "#3D4350",
      },
      type: "linear",
    },
  },
};
const BarChart = ({ sortedUsagesByMonth }: BarChartProps) => {
  const lastMonthUsage = sortedUsagesByMonth[sortedUsagesByMonth.length - 1];

  const lastMonthFormatted = moment(lastMonthUsage.month).format("YYYY-MM");

  const dataBarChar = {
    labels: sortedUsagesByMonth.map((mounts) =>
      capitalizeFirstLetter(moment(mounts.month).format("MMMM")).slice(0, 3)
    ),
    datasets: [
      {
        label: "kWh",
        backgroundColor: sortedUsagesByMonth.map((usage) => {
          return lastMonthFormatted === usage.month
            ? enerbitColors.primary.main
            : "#FFC390";
        }),
        data: sortedUsagesByMonth.map(
          (previous_usages) => previous_usages.usages?.active_energy_imported
        ),
      },
    ],
  };
  return <Bar data={dataBarChar} options={options} />;
};

export default BarChart;
