import { Box, formatterPeso, styled, Typography } from "@enerbit/base";
import { SettlementCardRowProps } from "../../../../../../../models/props/SettlementCardRow";
import { ContainerTitle } from "../../style-components-cards/CardComponent";

export const SettlementCardRowConcepts = ({
  title,
  fontWeight = 400,
  value2,
}: SettlementCardRowProps) => {
  return (
    <ContainerRowConcepts>
      <ContainerTitle>
        <Typography sx={{ fontSize: "14px", fontWeight }}>{title}</Typography>
      </ContainerTitle>
      <Box
        style={{ width: "23.1%" }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ fontWeight }}>
          {value2 !== null && value2 !== undefined
            ? formatterPeso.format(value2 as number)
            : "No hay valor disponible"}
        </Typography>
      </Box>
    </ContainerRowConcepts>
  );
};

export const ContainerRowConcepts = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  border-top: 1px solid #e4e7eb;
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
