import { Box, Typography, enerbitColors } from "@enerbit/base";
import { Charge } from "../../../../../../models/modals/settlement/dialogs/details/detail-right/ChargeTypes";
import { PlanEnerbitTypeName } from "../../../../../../utils/plansEnerbitUtils";
import {
  renderFixedCharge,
  renderVariableCharge,
} from "./charge-components/ChargeComponentsAndRenderers";
const textStyle = { color: enerbitColors.primary.main, fontWeight: "700" };

export const DetailSettlementPlanRight = ({
  namePlan,
  typesPlan,
}: {
  namePlan?: string;
  typesPlan?: Charge[];
}) => (
  <Box>
    <Typography style={textStyle}>
      {namePlan !== undefined ? namePlan : "No disponible"}
    </Typography>

    {typesPlan?.map((charge, chargeIndex) => {
      if (charge.name === PlanEnerbitTypeName.variable) {
        return renderVariableCharge(charge, chargeIndex);
      }
    })}

    {typesPlan?.map((charge, chargeIndex) => {
      if (charge.name === PlanEnerbitTypeName.fixed) {
        return renderFixedCharge(charge, chargeIndex);
      }
    })}
  </Box>
);
