import { getDialogComponentByName } from "../../utils/getGeneralModalEssBillingDetail";

export const InvoicesModals = ({
  serviceTypeName,
  invoiceId,
}: {
  invoiceId: string;
  serviceTypeName: string;
}) => {
  return getDialogComponentByName(serviceTypeName, invoiceId);
};
