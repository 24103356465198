import {
  Box,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  styled,
} from "@enerbit/base";
import { useState } from "react";

import { SettlementCardsProps } from "../../../../../../../models/props/SettlementCards";
import {
  CardContainer,
  CardHeader,
} from "../../style-components-cards/CardComponent";
import { SettlementCardRowTotalFooter } from "./SettlementCardRowTotalFooter";
import { SettlementCardRowTotalHeader } from "./SettlementCardRowTotalHeader";

const SettlementUsagesGraphsCard = ({
  invoice,
  settlement,
}: SettlementCardsProps) => {
  const [displayTariff, setDisplayTariff] = useState(true);

  return (
    <Box>
      {true &&
        invoice.details?.previous_usages &&
        invoice.details.previous_usages.length > 0 && (
          <CardContainer>
            <CardHeader
              sx={{
                background: "#D9D2E6",
                borderBottomLeftRadius: displayTariff ? "0rem" : "1rem",
                borderBottomRightRadius: displayTariff ? "0rem" : "1rem",
              }}
            >
              <SettlementCardRowTotalHeader
                backgroundColor={"var(--color-primary)"}
                title="Consumos de los últimos meses"
                fontWeight={700}
                color={"var(--color-primary)"}
              />
              <IconButton
                size="small"
                sx={{ color: "white" }}
                onClick={() => setDisplayTariff(!displayTariff)}
              >
                {displayTariff ? (
                  <KeyboardArrowDownIcon
                    sx={{ color: "var(--color-primary)" }}
                  />
                ) : (
                  <KeyboardArrowUpIcon sx={{ color: "var(--color-primary)" }} />
                )}
              </IconButton>
            </CardHeader>

            {displayTariff && (
              <CardFooter>
                <SettlementCardRowTotalFooter
                  title="Consumos de los últimos meses"
                  fontWeight={700}
                  settlement={settlement}
                  invoice={invoice}
                />
              </CardFooter>
            )}
          </CardContainer>
        )}
    </Box>
  );
};
export default SettlementUsagesGraphsCard;

export const CardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  min-height: 2rem;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: white;
  color: var(--color-neutral700);
`;
