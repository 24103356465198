import { useDispatch, useSelector } from "react-redux";
import { HistoryDialogProps } from "../../../../../../../../models/props/HistoryDialog";
import { AppDispatch } from "../../../../../../../../store/store";

import { Box, serviceNames } from "@enerbit/base";
import { useEffect } from "react";
import { StateStorage } from "../../../../../../../../models/stateStorage";
import { getInvoiceDetailFixedEnerbitElectricitySupply } from "../../../../../../../../store/actions/invoicesModals/invoicesModals.actions";
import { setOpenHistoryDialog } from "../../../../../../../../store/slices/invoicesModalsSlice";
import { lowercaseFirstLetter } from "../../../../../../../../utils/titleUtils";
import { FixedComponentContainer } from "../../../../settlement/containers/FixedComponentContainer";
import {
    DetailSettlementLeft,
    DetailSettlementPlanRight,
} from "../../../details";
import HistoryDialogFixedComponentHeader from "../history-dialog-settlement-header/HistoryDialogFixedComponentHeader";

//Componente Modal que contiene la Tabla del Historial

export const HistoryDialogFixedComponentContainer = ({
    invoiceId,
}: HistoryDialogProps) => {
    const { fixedEnerbitElectricitySupplyInvoiceDetail } = useSelector(
        (state: StateStorage) => state.invoicesModals,
    );
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (invoiceId) {
            dispatch(getInvoiceDetailFixedEnerbitElectricitySupply(invoiceId));
        }
        dispatch(setOpenHistoryDialog(true));
    }, []);
    const settlement = fixedEnerbitElectricitySupplyInvoiceDetail?.settlement;
    const issued_at =
        fixedEnerbitElectricitySupplyInvoiceDetail?.invoice?.issued_at;
    const start_consumption_date =
        fixedEnerbitElectricitySupplyInvoiceDetail?.settlement?.components
            ?.fixed_settlements?.start;
    const end_consumption_date =
        fixedEnerbitElectricitySupplyInvoiceDetail?.settlement?.components
            ?.fixed_settlements?.end;
    const service_agreement =
        fixedEnerbitElectricitySupplyInvoiceDetail?.service_agreement;
    const invoice = fixedEnerbitElectricitySupplyInvoiceDetail?.invoice;
    const namePlan = service_agreement?.name;
    const typesPlan = service_agreement?.conditions?.charges;
    return (
        <div>
            {fixedEnerbitElectricitySupplyInvoiceDetail ? (
                <HistoryDialogFixedComponentHeader
                    title={`Detalle liquidación factura ${lowercaseFirstLetter(
                        serviceNames[
                            fixedEnerbitElectricitySupplyInvoiceDetail.invoice
                                ?.service_type_name as keyof typeof serviceNames
                        ],
                    )}`}
                    showButtonBilling={true}
                    detailRight={
                        <DetailSettlementPlanRight
                            namePlan={namePlan}
                            typesPlan={typesPlan}
                        />
                    }
                    detailLeft={
                        <DetailSettlementLeft
                            dateStart={start_consumption_date}
                            dateEnd={end_consumption_date}
                            issuedAt={issued_at}
                        />
                    }
                    content={
                        <FixedComponentContainer
                            invoice={invoice}
                            service_agreement={service_agreement}
                            settlement={settlement}
                        />
                    }
                />
            ) : (
                <Box></Box>
            )}
        </div>
    );
};
