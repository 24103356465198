import { Typography, enerbitColors, formatterPeso } from "@enerbit/base";
import {
  Charge,
  ComponentIntervalTypographyProps,
  ComponentTypographyProps,
} from "../../../../../../../models/modals/settlement/dialogs/details/detail-right/ChargeTypes";
import { PlanEnerbitFixedComponentsName } from "../../../../../../../utils/plansEnerbitUtils";
const textStyle = { color: enerbitColors.primary.main, fontWeight: "700" };
const formatPrice = (price: number | undefined) => {
  if (price === undefined) {
    return "Este campo está vacío";
  }
  return formatterPeso.format(price);
};

export const ComponentTypography: React.FC<ComponentTypographyProps> = ({
  name,
  price,
  chargeIndex,
  componentIndex,
}) => (
  <Typography key={`charge-${chargeIndex}-component-${componentIndex}`}>
    <span style={textStyle}>{name}: </span>
    <span style={{ color: enerbitColors.primary.main }}>
      {price !== undefined ? `${formatPrice(price)} + IVA` : "No disponible"}
    </span>
  </Typography>
);

export const ComponentIntervalTypography: React.FC<
  ComponentIntervalTypographyProps
> = ({ price, chargeIndex }) => (
  <Typography key={`charge-${chargeIndex}-interval-0`}>
    <span style={textStyle}>G+C: </span>
    <span style={{ color: enerbitColors.primary.main }}>
      {price !== undefined && price !== null
        ? formatPrice(price)
        : "No disponible"}
    </span>
  </Typography>
);
export const renderVariableCharge = (charge: Charge, chargeIndex: number) => {
  if (charge.conditions?.intervals?.length === 1) {
    return (
      <ComponentIntervalTypography
        key={`charge-${chargeIndex}-interval-0`}
        price={charge.conditions.intervals[0].price}
        chargeIndex={chargeIndex}
      />
    );
  }
};

export const renderFixedCharge = (charge: Charge, chargeIndex: number) => {
  if (!charge?.conditions?.components) return;

  const sortedComponents = [...charge.conditions.components].sort(
    (componentsTechTools, componentsMeterRent) => {
      if (componentsTechTools.name === PlanEnerbitFixedComponentsName.techTools)
        return -1;
      if (componentsMeterRent.name === PlanEnerbitFixedComponentsName.techTools)
        return 1;
      return 0;
    }
  );

  return sortedComponents.map((condition, componentIndex) => {
    if (condition.name === PlanEnerbitFixedComponentsName.techTools) {
      return (
        <ComponentTypography
          key={`charge-${chargeIndex}-component-${componentIndex}`}
          name="Fijo"
          price={condition.price}
          chargeIndex={chargeIndex}
          componentIndex={componentIndex}
        />
      );
    }
    if (condition.name === PlanEnerbitFixedComponentsName.meterRent) {
      return (
        <ComponentTypography
          key={`charge-${chargeIndex}-component-${componentIndex}`}
          name="Alquiler medidor"
          price={condition.price}
          chargeIndex={chargeIndex}
          componentIndex={componentIndex}
        />
      );
    }
  });
};
