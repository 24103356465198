import { PreviousUsage } from "../models/modals/settlement/ElectricitySupplyService";

export const sortUsagesByDateAscending = (previousUsages: PreviousUsage[]) => {
  return previousUsages
    ? [...previousUsages].sort((a, b) => {
        if (a.month && b.month) {
          return new Date(a.month).getTime() - new Date(b.month).getTime();
        }
        return 0;
      })
    : [];
};
