import {
  ThemeConfig,
  cleanSessionStorage,
  initEnviroment,
} from "@enerbit/base";
import { Provider } from "react-redux";
import * as singleSpa from "single-spa";
import "./assets/css/login.scss";
import { InvoicesModals } from "./pages/invoices-modals/InvoicesModals";
import { store } from "./store/store";
import "./styles/App.scss";
export interface InvoicesModalsProps {
  invoiceId: string;
  serviceTypeName: string;
}

const closeSession = () => {
  cleanSessionStorage();
  singleSpa.navigateToUrl("/");
};

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root({
  invoiceId,
  serviceTypeName,
}: InvoicesModalsProps) {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <InvoicesModals
          invoiceId={invoiceId}
          serviceTypeName={serviceTypeName}
        />
      </ThemeConfig>
    </Provider>
  );
}
