import { useDispatch, useSelector } from "react-redux";
import { HistoryDialogProps } from "../../../../../../../../models/props/HistoryDialog";
import { AppDispatch } from "../../../../../../../../store/store";

import { Box } from "@enerbit/base";
import { useEffect } from "react";
import { StateStorage } from "../../../../../../../../models/stateStorage";
import { getInvoiceDetailElectricitySupplyService } from "../../../../../../../../store/actions/invoicesModals/invoicesModals.actions";
import { setOpenHistoryDialog } from "../../../../../../../../store/slices/invoicesModalsSlice";
import { ElectricitySupplyServiceContainer } from "../../../../settlement/containers/ElectricitySupplyServiceContainer";
import {
  DetailSettlementElectricitySupplyRight,
  DetailSettlementLeft,
} from "../../../details";
import HistoryDialogElectricitySupplyServiceHeader from "../history-dialog-settlement-header/HistoryDialogElectricitySupplyServiceHeader";

//Componente Modal que contiene la Tabla del Historial

export const HistoryDialogElectricitySupplyServiceContainer = ({
  invoiceId,
}: HistoryDialogProps) => {
  const { electricitySupplyServiceInvoiceDetail } = useSelector(
    (state: StateStorage) => state.invoicesModals
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (invoiceId) {
      dispatch(getInvoiceDetailElectricitySupplyService(invoiceId));
    }
    dispatch(setOpenHistoryDialog(true));
  }, []);
  const settlement = electricitySupplyServiceInvoiceDetail?.settlement;
  const invoice = electricitySupplyServiceInvoiceDetail?.invoice;
  const serviceAgreement =
    electricitySupplyServiceInvoiceDetail?.service_agreement;
  const startConsumptionDate =
    electricitySupplyServiceInvoiceDetail?.settlement?.start;
  const endConsumptionDate =
    electricitySupplyServiceInvoiceDetail?.settlement?.end;
  return (
    <div>
      {electricitySupplyServiceInvoiceDetail ? (
        <HistoryDialogElectricitySupplyServiceHeader
          title={"Detalle liquidación factura de energía"}
          showButtonBilling={true}
          detailRight={
            <DetailSettlementElectricitySupplyRight
              invoice={invoice}
              serviceAgreement={serviceAgreement}
            />
          }
          detailLeft={
            <DetailSettlementLeft
              dateStart={startConsumptionDate}
              dateEnd={endConsumptionDate}
              issuedAt={invoice?.issued_at}
            />
          }
          content={
            <ElectricitySupplyServiceContainer
              invoice={invoice}
              settlement={settlement}
            />
          }
        />
      ) : (
        <Box></Box>
      )}
    </div>
  );
};
