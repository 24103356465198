import { Box, Typography, styled } from "@enerbit/base";

import { FixedEnerbitElectricitySupplyInvoiceDetail } from "../../../../../../models/invoicesModals/responses/FixedEnerbitElectricitySupplyInvoiceDetail.model";
import {
  PlanEnerbitFixedComponentsName,
  PlanEnerbitTypeName,
} from "../../../../../../utils/plansEnerbitUtils";
import {
  SettlementCardRowConcepts,
  SettlementCardRowTotalHeader,
} from "../settlement-cards/energy-regulate-card";
import {
  CardBody,
  CardBodyTitle,
  CardContainer,
  CardHeader,
} from "../style-components-cards/CardComponent";

export const FixedComponentContainer = ({
  invoice,
  service_agreement,
  settlement,
}: FixedEnerbitElectricitySupplyInvoiceDetail) => {
  const taxAmount =
    invoice && invoice.components && invoice.components.length > 0
      ? invoice.components[0]?.tax_amount
      : NaN;

  return (
    <>
      <Box>
        {invoice && (
          <Box sx={{ mb: "2rem" }}>
            <CardContainer>
              <CardHeader>
                <SettlementCardRowTotalHeader
                  title="Total servicio enerBit componente fija"
                  color="white"
                  backgroundColor={"var(--color-secondary)"}
                  fontWeight={700}
                  value1={Number(invoice.legal_payable_amount)?.toFixed(2)}
                />
              </CardHeader>
              <CardBody>
                <CardBodyTitle>
                  <Box
                    style={{ width: "20%" }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 1rem",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "700", color: "var(--color-primary)" }}
                    >
                      Concepto
                    </Typography>
                  </Box>
                </CardBodyTitle>
              </CardBody>
              <CardFooter>
                {service_agreement?.conditions?.charges?.map((charge) => {
                  if (
                    charge.name === PlanEnerbitTypeName.fixed &&
                    charge?.conditions?.components
                  ) {
                    return [...charge?.conditions?.components]
                      ?.sort((componentsTechTools, componentsMeterRent) => {
                        if (
                          componentsTechTools.name ===
                          PlanEnerbitFixedComponentsName.techTools
                        )
                          return -1;
                        if (
                          componentsMeterRent.name ===
                          PlanEnerbitFixedComponentsName.techTools
                        )
                          return 1;
                        return 0;
                      })
                      .map((condition) => {
                        if (
                          condition.name ===
                          PlanEnerbitFixedComponentsName.techTools
                        ) {
                          return (
                            <SettlementCardRowConcepts
                              title="Valor fijo"
                              value2={condition.price}
                            />
                          );
                        }
                        if (
                          condition.name ===
                          PlanEnerbitFixedComponentsName.meterRent
                        ) {
                          return (
                            <SettlementCardRowConcepts
                              title="Alquiler de medidor"
                              value2={condition.price}
                            />
                          );
                        }
                        return null;
                      });
                  }
                  return null;
                })}
                <SettlementCardRowConcepts title="IVA" value2={taxAmount} />
              </CardFooter>
            </CardContainer>
          </Box>
        )}
      </Box>
    </>
  );
};
export const CardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  min-height: 2rem;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: white;
  color: var(--color-neutral700);
  flex-direction: column;
`;
