function customFormat(num: number): string {
  if (Number.isInteger(num)) {
    return new Intl.NumberFormat("es-CO").format(num);
  } else {
    return new Intl.NumberFormat("es-CO", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  }
}

export const formatDecimal = {
  format: customFormat,
};
