import {
  Box,
  Button,
  CircularProgress,
  CustomAlert,
  Dialog,
  DialogActions,
  DialogContent,
  DownloadOutlinedIcon,
  Grid,
  HighlightOffOutlinedIcon,
  LoadingButton,
  theme,
  Typography,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { HistoryDialogBillingProps } from "../../../../../../../../models/props/HistoryDialog";

import { StateStorage } from "../../../../../../../../models/stateStorage";
import { downloadInvoice } from "../../../../../../../../store/actions/invoicesModals/invoicesModals.actions";
import {
  resetBillingState,
  setOpenHistoryDialog,
} from "../../../../../../../../store/slices/invoicesModalsSlice";
import { AppDispatch } from "../../../../../../../../store/store";
const HistoryDialogFixedComponentHeader = ({
  title,
  showButtonBilling,
  content,
  detailLeft,
  detailRight,
}: HistoryDialogBillingProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    fixedEnerbitElectricitySupplyInvoiceDetail,
    openHistoryDialog,
    fixedEnerbitElectricitySupplyBillingDetailError,
    isLoadingFixedEnerbitElectricitySupplyInvoiceDetail,
    isLoadingDownloadInvoice,
  } = useSelector((state: StateStorage) => state.invoicesModals);

  return (
    <Dialog
      open={openHistoryDialog}
      onClose={() => dispatch(setOpenHistoryDialog(false))}
      fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          p: "25px 30px",
          borderRadius: "20px",
          boxShadow: "none",
        },
      }}
    >
      {" "}
      {isLoadingFixedEnerbitElectricitySupplyInvoiceDetail ? (
        <>
          <Box
            display="flex"
            gap="1rem"
            sx={{ alignItems: "center", flexDirection: "column" }}
          >
            <Typography
              fontSize={26}
              fontWeight={"bold"}
              color={theme.palette.primary.main}
            >
              Cargando detalle de factura de servicio enerBit de componente fija
            </Typography>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          {fixedEnerbitElectricitySupplyBillingDetailError ? (
            <>
              {" "}
              <DialogActions style={{ marginBottom: "2rem" }}>
                <Button
                  onClick={() => {
                    dispatch(setOpenHistoryDialog(false)),
                      dispatch(resetBillingState());
                  }}
                  sx={{
                    "& .MuiButtonBase-root": {
                      borderRadius: "100%",
                    },
                  }}
                >
                  <HighlightOffOutlinedIcon fontSize="large" />
                </Button>
              </DialogActions>
              <CustomAlert
                severity="error"
                text={fixedEnerbitElectricitySupplyBillingDetailError}
                onClose={() => null}
              />
            </>
          ) : (
            <>
              <Box className="Container-history-dialog-billing">
                <Box>
                  <Typography
                    color={theme.palette.secondary.main}
                    sx={{ fontSize: "28px", fontWeight: "700" }}
                  >
                    {title}
                  </Typography>
                </Box>
                <DialogActions style={{ padding: "0px" }}>
                  <Button
                    onClick={() => dispatch(setOpenHistoryDialog(false))}
                    sx={{
                      "& .MuiButtonBase-root": {
                        borderRadius: "100%",
                      },
                    }}
                  >
                    <HighlightOffOutlinedIcon fontSize="large" />
                  </Button>
                </DialogActions>
              </Box>
              <Grid
                container
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Grid item xs={12} md={6}>
                  {detailLeft}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {showButtonBilling && (
                    <Box
                      sx={{
                        mr: "1.5rem",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        loading={isLoadingDownloadInvoice ? true : false}
                        sx={{ padding: "0.8rem 1.8rem !important" }}
                        className="Loading-button-modal-invoices"
                        onClick={() => {
                          if (
                            fixedEnerbitElectricitySupplyInvoiceDetail?.invoice
                              ?.id
                          ) {
                            dispatch(
                              downloadInvoice(
                                fixedEnerbitElectricitySupplyInvoiceDetail
                                  ?.invoice?.id
                              )
                            );
                          }
                        }}
                      >
                        <DownloadOutlinedIcon
                          sx={{ fontSize: "20px", mr: "3px" }}
                        />
                        Factura
                      </LoadingButton>
                    </Box>
                  )}
                  {detailRight}
                </Grid>
              </Grid>
              <Box
                sx={{
                  borderTop: "1px solid #A3A9B6",
                  margin: "1.5rem 0 1.3rem",
                }}
              ></Box>
            </>
          )}
        </>
      )}
      <DialogContent sx={{ p: "25px 0" }}>{content}</DialogContent>
    </Dialog>
  );
};

export default HistoryDialogFixedComponentHeader;
