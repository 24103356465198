import { formatTwoDecimals, formatterPeso, theme } from "@enerbit/base";
import { Invoice } from "../../../../../../models/typesShared";
import { getInvoiceComponentActiveEnergyImported } from "../../../../../../utils/billingUtils";
import {
  Card,
  CardsContainer,
  TypographyLabel,
  TypographyValue,
} from "../style-components-cards/CardComponent";

const CardsEnergyRegulated = ({ invoice }: { invoice: Invoice }) => {
  const rateApplied = getInvoiceComponentActiveEnergyImported(invoice)?.price;
  const totalCardHeader =
    getInvoiceComponentActiveEnergyImported(invoice)?.amount;

  const consumption =
    getInvoiceComponentActiveEnergyImported(invoice)?.quantity;

  return (
    <CardsContainer>
      {true && (
        <>
          <Card
            sx={{
              padding: "10px 40px",
            }}
          >
            <TypographyLabel color={theme.palette.primary.main}>
              Consumo
            </TypographyLabel>
            <TypographyValue color={theme.palette.primary.main}>
              {consumption
                ? `${formatTwoDecimals.format(consumption as number)} kWh`
                : "No hay valor disponible"}
            </TypographyValue>
          </Card>
          <Card
            sx={{
              background: "transparent",
              width: "10%",
            }}
          >
            <TypographyValue
              sx={{ fontWeight: "700", fontSize: "40px" }}
              color={theme.palette.primary.main}
            >
              x
            </TypographyValue>
          </Card>

          <Card
            sx={{
              padding: "10px 40px",
            }}
          >
            <TypographyLabel color={theme.palette.primary.main}>
              Tarifa aplicada
            </TypographyLabel>
            <TypographyValue color={theme.palette.primary.main}>
              {consumption
                ? `${formatterPeso.format(rateApplied as number)}`
                : "No hay valor disponible"}
            </TypographyValue>
          </Card>
          <Card
            sx={{
              background: "transparent",
              width: "10%",
            }}
          >
            <TypographyValue
              sx={{ fontWeight: "700", fontSize: "40px" }}
              color={theme.palette.primary.main}
            >
              =
            </TypographyValue>
          </Card>
          <Card
            sx={{
              padding: "10px 40px 10px 3%",
              width: "90%",
            }}
          >
            <TypographyLabel color={theme.palette.primary.main}>
              Total
            </TypographyLabel>
            <TypographyValue color={theme.palette.primary.main}>
              {consumption
                ? `${formatterPeso.format(totalCardHeader as number)}`
                : "No hay valor disponible"}
            </TypographyValue>
          </Card>
        </>
      )}
    </CardsContainer>
  );
};

export default CardsEnergyRegulated;
