import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { EnerbitServicesInvoiceDetail } from "../../models/invoicesModals/responses/EnerbitServicesInvoiceDetail.model";

import { ElectricitySupplyServiceInvoiceDetail } from "../../models/invoicesModals/responses/ElectricitySupplyServiceInvoiceDetail.model";
import { FixedEnerbitElectricitySupplyInvoiceDetail } from "../../models/invoicesModals/responses/FixedEnerbitElectricitySupplyInvoiceDetail.model";
import { ThirdPartyServicesDetail } from "../../models/invoicesModals/responses/ThirdPartyServicesDetail.model";
import { InvoicesModalsState } from "../../models/states/InvoicesModalsState";
import {
  downloadInvoice,
  getInvoiceDetailElectricitySupplyService,
  getInvoiceDetailEnerbitElectricitySupply,
  getInvoiceDetailFixedEnerbitElectricitySupply,
  getInvoiceDetailThirdPartyServices,
} from "../actions/invoicesModals/invoicesModals.actions";

const initialState: InvoicesModalsState = {
  electricitySupplyServiceInvoiceDetail: {},
  isLoadingElectricitySupplyServiceInvoiceDetail: false,
  electricitySupplyServiceInvoiceDetailError: "",
  fixedEnerbitElectricitySupplyInvoiceDetail: {},
  isLoadingFixedEnerbitElectricitySupplyInvoiceDetail: false,
  fixedEnerbitElectricitySupplyBillingDetailError: "",
  enerbitServicesBillingDetail: {},
  isLoadingEnerbitServicesBillingDetail: false,
  enerbitServicesBillingDetailError: "",
  thirdPartyServices: {},
  isLoadingThirdPartyServices: false,
  thirdPartyServicesError: "",
  openHistoryDialog: false,
  params: {},
  invoiceDownload: "",
  isLoadingDownloadInvoice: false,
  errorDownloadInvoice: false,
  successDownloadInvoice: false,
};

export const invoicesModalsSlice = createSlice({
  name: "invoicesModals",
  initialState,
  reducers: {
    setOpenHistoryDialog: (state, action: PayloadAction<boolean>) => {
      state.openHistoryDialog = action.payload;
    },
    resetBillingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getInvoiceDetailElectricitySupplyService.pending.type,
        (state) => {
          state.isLoadingElectricitySupplyServiceInvoiceDetail = true;
          state.electricitySupplyServiceInvoiceDetail = {};
        }
      )
      .addCase(
        getInvoiceDetailElectricitySupplyService.fulfilled.type,
        (
          state,
          action: PayloadAction<ElectricitySupplyServiceInvoiceDetail>
        ) => {
          state.isLoadingElectricitySupplyServiceInvoiceDetail = false;
          state.electricitySupplyServiceInvoiceDetail = action.payload;
        }
      )
      .addCase(
        getInvoiceDetailElectricitySupplyService.rejected.type,
        (state, action: PayloadAction<{ status: number }>) => {
          state.isLoadingElectricitySupplyServiceInvoiceDetail = false;
          if (action.payload.status === 404) {
            state.electricitySupplyServiceInvoiceDetailError =
              "No se encontro factura de electricidad.";
          } else if (action.payload.status === 500) {
            state.electricitySupplyServiceInvoiceDetailError =
              "Error del servidor.";
          } else {
            state.electricitySupplyServiceInvoiceDetailError =
              "Error desconocido del servidor, no se pudo obtener la factura de electricidad.";
          }
        }
      )
      .addCase(
        getInvoiceDetailFixedEnerbitElectricitySupply.pending.type,
        (state) => {
          state.isLoadingFixedEnerbitElectricitySupplyInvoiceDetail = true;
          state.fixedEnerbitElectricitySupplyInvoiceDetail = {};
        }
      )
      .addCase(
        getInvoiceDetailFixedEnerbitElectricitySupply.fulfilled.type,
        (
          state,
          action: PayloadAction<FixedEnerbitElectricitySupplyInvoiceDetail>
        ) => {
          state.isLoadingFixedEnerbitElectricitySupplyInvoiceDetail = false;
          state.fixedEnerbitElectricitySupplyInvoiceDetail = action.payload;
        }
      )
      .addCase(
        getInvoiceDetailFixedEnerbitElectricitySupply.rejected.type,
        (state, action: PayloadAction<{ status: number }>) => {
          state.isLoadingFixedEnerbitElectricitySupplyInvoiceDetail = false;
          if (action.payload.status === 404) {
            state.fixedEnerbitElectricitySupplyBillingDetailError =
              "No se encontro factura de servicio enerBit de componente fija.";
          } else if (action.payload.status === 500) {
            state.fixedEnerbitElectricitySupplyBillingDetailError =
              "Error del servidor.";
          } else {
            state.fixedEnerbitElectricitySupplyBillingDetailError =
              "Error desconocido del servidor, no se pudo obtener la factura de servicio enerBit de componente fija.";
          }
        }
      )
      .addCase(
        getInvoiceDetailEnerbitElectricitySupply.pending.type,
        (state) => {
          state.isLoadingEnerbitServicesBillingDetail = true;
          state.enerbitServicesBillingDetail = {};
        }
      )
      .addCase(
        getInvoiceDetailEnerbitElectricitySupply.fulfilled.type,
        (state, action: PayloadAction<EnerbitServicesInvoiceDetail>) => {
          state.isLoadingEnerbitServicesBillingDetail = false;
          state.enerbitServicesBillingDetail = action.payload;
        }
      )
      .addCase(
        getInvoiceDetailEnerbitElectricitySupply.rejected.type,
        (state, action: PayloadAction<{ status: number }>) => {
          state.isLoadingEnerbitServicesBillingDetail = false;
          if (action.payload.status === 404) {
            state.enerbitServicesBillingDetailError =
              "No se encontro factura de servicios enerBit.";
          } else if (action.payload.status === 500) {
            state.enerbitServicesBillingDetailError = "Error del servidor.";
          } else {
            state.enerbitServicesBillingDetailError =
              "Error desconocido del servidor, no se pudo obtener la factura de servicios enerBit.";
          }
        }
      )
      .addCase(getInvoiceDetailThirdPartyServices.pending.type, (state) => {
        state.isLoadingThirdPartyServices = true;
        state.thirdPartyServices = {};
      })
      .addCase(
        getInvoiceDetailThirdPartyServices.fulfilled.type,
        (state, action: PayloadAction<ThirdPartyServicesDetail>) => {
          state.isLoadingThirdPartyServices = false;
          state.thirdPartyServices = action.payload;
        }
      )
      .addCase(
        getInvoiceDetailThirdPartyServices.rejected.type,
        (state, action: PayloadAction<{ status: number }>) => {
          state.isLoadingThirdPartyServices = false;
          if (action.payload.status === 404) {
            state.thirdPartyServicesError =
              "No se encontro factura de servicio de terceros.";
          } else if (action.payload.status === 500) {
            state.thirdPartyServicesError = "Error del servidor.";
          } else {
            state.thirdPartyServicesError =
              "Error desconocido del servidor, no se pudo obtener la factura de servicio de terceros.";
          }
        }
      )
      .addCase(downloadInvoice.pending.type, (state) => {
        state.isLoadingDownloadInvoice = true;
      })
      .addCase(downloadInvoice.rejected.type, (state) => {
        state.isLoadingDownloadInvoice = false;
        state.invoiceDownload = "";
        state.errorDownloadInvoice = true;
        state.successDownloadInvoice = false;
      })
      .addCase(downloadInvoice.fulfilled.type, (state) => {
        state.isLoadingDownloadInvoice = false;
        state.invoiceDownload = "";
        state.successDownloadInvoice = true;
        state.errorDownloadInvoice = false;
      });
  },
});
export const { setOpenHistoryDialog, resetBillingState } =
  invoicesModalsSlice.actions;
export default invoicesModalsSlice.reducer;
