import { useDispatch, useSelector } from "react-redux";
import { HistoryDialogProps } from "../../../../../../../../models/props/HistoryDialog";
import { AppDispatch } from "../../../../../../../../store/store";

import { Box } from "@enerbit/base";
import { useEffect } from "react";
import { StateStorage } from "../../../../../../../../models/stateStorage";
import { getInvoiceDetailEnerbitElectricitySupply } from "../../../../../../../../store/actions/invoicesModals/invoicesModals.actions";

import { setOpenHistoryDialog } from "../../../../../../../../store/slices/invoicesModalsSlice";
import { EnerbitServicesContainer } from "../../../../settlement/containers";
import {
  DetailSettlementLeft,
  DetailSettlementPlanRight,
} from "../../../details";
import HistoryDialogEnerbitServicesHeader from "../history-dialog-settlement-header/HistoryDialogEnerbitServicesHeader";

//Componente Modal que contiene la Tabla del Historial

export const HistoryDialogEnerbitServicesContainer = ({
  invoiceId,
}: HistoryDialogProps) => {
  const { enerbitServicesBillingDetail } = useSelector(
    (state: StateStorage) => state.invoicesModals
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (invoiceId) {
      dispatch(getInvoiceDetailEnerbitElectricitySupply(invoiceId));
    }
    dispatch(setOpenHistoryDialog(true));
  }, []);

  const enerbit_service_agreement =
    enerbitServicesBillingDetail?.enerbit_service_agreement;
  const namePlan = enerbit_service_agreement?.name;
  const typesPlan = enerbit_service_agreement?.conditions?.charges;
  return (
    <div>
      {enerbitServicesBillingDetail ? (
        <HistoryDialogEnerbitServicesHeader
          title={"Detalle liquidación factura servicios enerBit"}
          showButtonBilling={true}
          detailRight={
            <DetailSettlementPlanRight
              namePlan={namePlan}
              typesPlan={typesPlan}
            />
          }
          detailLeft={
            <DetailSettlementLeft
              dateStart={enerbitServicesBillingDetail.metering_since}
              dateEnd={enerbitServicesBillingDetail.metering_until}
              issuedAt={enerbitServicesBillingDetail.invoice_issued_at}
            />
          }
          content={
            <EnerbitServicesContainer
              enerbitServicesBillingDetail={enerbitServicesBillingDetail}
            />
          }
        />
      ) : (
        <Box></Box>
      )}
    </div>
  );
};
