import {
  Box,
  FlashOnOutlinedIcon,
  formatterPeso,
  Typography,
} from "@enerbit/base";
import { SettlementCardRowTotalHeaderProps } from "../../../../../../../models/props/SettlementCardRow";
import {
  ContainerCardRowTotalHeader,
  ContainerTitleAndIcon,
  Icon,
} from "../../style-components-cards/CardComponent";

export const SettlementCardRowTotalHeader = ({
  title,
  fontWeight = 700,
  backgroundColor,
  color,
  value1,
}: SettlementCardRowTotalHeaderProps) => {
  return (
    <ContainerCardRowTotalHeader>
      <ContainerTitleAndIcon>
        <Icon
          sx={{
            background: backgroundColor,
            padding: "0.2rem 0rem !important",
            minWidth: "30px",
          }}
        >
          <FlashOnOutlinedIcon fontSize="small" />
        </Icon>

        <Typography sx={{ fontSize: "16px", fontWeight, color: color }}>
          {title}
        </Typography>
      </ContainerTitleAndIcon>

      <Box
        style={{ width: "20%" }}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        {value1 !== null && value1 !== undefined ? (
          <Typography sx={{ fontSize: "16px", fontWeight, color }}>
            {formatterPeso.format(Number(value1))}
          </Typography>
        ) : (
          <Box>No hay valor disponible</Box>
        )}
      </Box>
    </ContainerCardRowTotalHeader>
  );
};
