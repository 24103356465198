import { ServiceTypeNames } from "@enerbit/base";
import {
  HistoryDialogElectricitySupplyServiceContainer,
  HistoryDialogEnerbitServicesContainer,
  HistoryDialogFixedComponentContainer,
  HistoryDialogThirdPartyServicesContainer,
} from "../pages/invoices-modals/Components/modal-invoice-information/dialogs/history-dialog/history-dialog-settlement/history-dialog-settlement-container";

export const getDialogComponentByName = (
  serviceTypeName: string,
  invoiceId: string
): JSX.Element => {
  if (serviceTypeName === ServiceTypeNames.electricitySupplyServices) {
    return (
      <HistoryDialogElectricitySupplyServiceContainer invoiceId={invoiceId} />
    );
  } else if (
    serviceTypeName === ServiceTypeNames.fixedEnerbitElectricitySupplyServices
  ) {
    return <HistoryDialogFixedComponentContainer invoiceId={invoiceId} />;
  } else if (
    serviceTypeName === ServiceTypeNames.enerbitElectricitySupplyServices
  ) {
    return <HistoryDialogEnerbitServicesContainer invoiceId={invoiceId} />;
  } else {
    return <HistoryDialogThirdPartyServicesContainer invoiceId={invoiceId} />;
  }
};
