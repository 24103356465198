import { Box, Typography, styled, thirdPartyServiceNames } from "@enerbit/base";

import { ThirdPartyServicesDetail } from "../../../../../../models/invoicesModals/responses/ThirdPartyServicesDetail.model";
import { lowercaseFirstLetter } from "../../../../../../utils/titleUtils";
import {
    SettlementCardRowConcepts,
    SettlementCardRowTotalHeader,
} from "../settlement-cards/energy-regulate-card";
import {
    CardBody,
    CardBodyTitle,
    CardContainer,
    CardHeader,
} from "../style-components-cards/CardComponent";

export const ThirdPartyServicesContainer = ({
    invoice,
}: ThirdPartyServicesDetail) => {
    const taxAmount =
        invoice && invoice.components && invoice.components.length > 0
            ? invoice.components[0]?.tax_amount
            : NaN;

    return (
        <>
            <Box>
                {invoice && (
                    <Box sx={{ mb: "2rem" }}>
                        <CardContainer>
                            <CardHeader>
                                <SettlementCardRowTotalHeader
                                    title={`Total ${lowercaseFirstLetter(
                                        thirdPartyServiceNames[
                                            invoice.service_type_name as keyof typeof thirdPartyServiceNames
                                        ],
                                    )}`}
                                    color="white"
                                    backgroundColor={"var(--color-secondary)"}
                                    fontWeight={700}
                                    value1={Number(
                                        invoice.legal_payable_amount,
                                    )?.toFixed(2)}
                                />
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 34,
                                    }}
                                ></Box>
                            </CardHeader>
                            <CardBody>
                                <CardBodyTitle>
                                    <Box
                                        style={{ width: "20%" }}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0 1rem",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "700",
                                                color: "var(--color-primary)",
                                            }}
                                        >
                                            Concepto
                                        </Typography>
                                    </Box>
                                </CardBodyTitle>
                            </CardBody>
                            <CardFooter>
                                {invoice?.components?.map((component) => (
                                    <SettlementCardRowConcepts
                                        title={
                                            component.description ??
                                            "Titulo no disponible"
                                        }
                                        value2={component.price}
                                    />
                                ))}
                            </CardFooter>
                        </CardContainer>
                    </Box>
                )}
            </Box>
        </>
    );
};
export const CardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  min-height: 2rem;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: white;
  color: var(--color-neutral700);
  flex-direction: column;
`;
