import {
  Box,
  Typography,
  enerbitColors,
  styled,
  useMediaQuery,
} from "@enerbit/base";

import moment from "moment";
import { formatDecimal } from "../../../../../../../helpers/formatDecimals";
import { SettlementCardRowUsagesGraphsProps } from "../../../../../../../models/props/SettlementCardRow";
import BarChart from "../../../../../../../utils/bar-chart/BarChart";
import { getInvoiceComponentActiveEnergyImported } from "../../../../../../../utils/billingUtils";
import { sortUsagesByDateAscending } from "../../../../../../../utils/sortUsagesByDateAscending";

export const SettlementCardRowTotalFooter = ({
  invoice,
  settlement,
}: SettlementCardRowUsagesGraphsProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 849px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 850px) and (max-width: 1080px)"
  );
  const isLargeScreen = useMediaQuery("(min-width: 901px)");
  const currentMonthUsage = {
    month: moment(settlement?.start).format("YYYY-MM"),
    usages: {
      active_energy_imported:
        getInvoiceComponentActiveEnergyImported(invoice)?.quantity,
    },
  };
  const currentAndPreviousMonthsUsage = [
    currentMonthUsage,
    ...(invoice.details?.previous_usages ?? []),
  ];

  const sortedUsagesByMonth = sortUsagesByDateAscending(
    currentAndPreviousMonthsUsage
  );

  return (
    <>
      <ContainerComponents>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {invoice.details?.previous_usages ? (
            <>
              <Box sx={{ width: "90%", height: "310px" }}>
                <BarChart sortedUsagesByMonth={sortedUsagesByMonth} />
              </Box>
              <TextContainer
                isSmallScreen={isSmallScreen}
                isMediumScreen={isMediumScreen}
                isLargeScreen={isLargeScreen}
                sx={{
                  ...(sortedUsagesByMonth.length <= 3 && {
                    justifyContent: "space-around !important",
                  }),
                  ...(sortedUsagesByMonth.length >= 4 && {
                    justifyContent: "space-between !important",
                  }),
                }}
              >
                {sortedUsagesByMonth.map((previous_usages, index) => (
                  <TextItem key={index}>
                    <Typography
                      sx={{ color: "var(--color-neutral500)", pl: "1rem" }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: "bold",
                          color: enerbitColors.neutral.main,
                        }}
                      >
                        {formatDecimal.format(
                          previous_usages.usages
                            ?.active_energy_imported as number
                        )}
                      </Typography>{" "}
                      kWh
                    </Typography>
                  </TextItem>
                ))}
              </TextContainer>
            </>
          ) : (
            <Box>No hay valor disponible</Box>
          )}
        </Box>
      </ContainerComponents>
    </>
  );
};

const ContainerComponents = styled("div")`
  display: flex;
  width: 100%;
  padding: 0.625rem 0;
  justify-content: space-between;
  color: var(--color-neutral700);
  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
const TextContainer = styled("div")<{
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  isLargeScreen: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${({ isSmallScreen, isMediumScreen, isLargeScreen }) =>
    isSmallScreen
      ? "0.625rem 3.5rem 0 8rem"
      : isMediumScreen
      ? "0.625rem 5rem 0 9.1rem"
      : isLargeScreen
      ? "0.625rem 5.5rem 0 9rem;"
      : "0.625rem 5rem 0 10rem"};
  justify-content: space-between;
  color: var(--color-neutral700);
  margin: 8px;
`;

const TextItem = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
