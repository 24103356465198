import { CardComponentProps } from "../../../../../../../models/props/CardComponentProps";
import {
  Card,
  TypographyLabel,
  TypographyValue,
} from "../../style-components-cards/CardComponent";

export const CardComponent = ({
  title,
  color,
  value1,
  suffix,
}: CardComponentProps) => {
  return (
    <Card style={{ minHeight: "5rem", height: "auto" }}>
      <TypographyLabel color={color}>{title}</TypographyLabel>
      <TypographyValue color={color}>
        {value1} {suffix || ""}
      </TypographyValue>
    </Card>
  );
};
