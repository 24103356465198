import { Typography } from "@enerbit/base";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { SettlementCardRowProps } from "../../../../../../../models/props/SettlementCardRow";
import {
  ContainerCardRowTotalHeader,
  ContainerTitleAndIcon,
  Icon,
} from "../../style-components-cards/CardComponent";

export const SettlementCardRowTotalHeader = ({
  title,
  fontWeight = 700,
  backgroundColor,
  color,
}: SettlementCardRowProps) => {
  return (
    <ContainerCardRowTotalHeader>
      <ContainerTitleAndIcon>
        <Icon
          sx={{
            background: backgroundColor,
            padding: "0.2rem 0rem !important",
            minWidth: "30px",
          }}
        >
          <BarChartOutlinedIcon fontSize="small" />
        </Icon>

        <Typography sx={{ fontSize: "16px", fontWeight, color: color }}>
          {title}
        </Typography>
      </ContainerTitleAndIcon>
    </ContainerCardRowTotalHeader>
  );
};
