import React from "react";

// Función auxiliar para crear el título con una letra negrilla
export function createTitleWithBoldLetter(
  title: string | React.ReactElement,
  boldLetter: string
): string | React.ReactElement {
  if (React.isValidElement(title)) {
    return title;
  } else if (typeof title === "string") {
    const characters = title.split("");
    return (
      <>
        {characters.map((char, index) =>
          char === boldLetter ? <strong key={index}>{boldLetter}</strong> : char
        )}
      </>
    );
  } else {
    return title;
  }
}

// Función auxiliar para crear todo título con negrilla
export function createTitleWithAllBoldLetters(title: string) {
  return title
    .split("")
    .map((char, index) => <strong key={index}>{char}</strong>);
}

// Función auxiliar para crear todo título en minuscula

export function lowercaseFirstLetter(string: string | undefined) {
  if (typeof string !== "string" || string.length === 0) {
    return "";
  }
  return string.charAt(0).toLowerCase() + string.slice(1);
}
