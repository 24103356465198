import { Box, formatterPeso, Typography } from "@enerbit/base";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { SettlementCardRowTotalHeaderProps } from "../../../../../../../models/props/SettlementCardRow";
import {
  ContainerCardRowTotalHeader,
  ContainerTitleAndIcon,
  Icon,
} from "../../style-components-cards/CardComponent";

export const SettlementCardRowTotalHeader = ({
  title,
  fontWeight = 700,
  backgroundColor,
  color,
  value1,
}: SettlementCardRowTotalHeaderProps) => {
  return (
    <ContainerCardRowTotalHeader>
      <ContainerTitleAndIcon>
        <Icon
          sx={{
            background: backgroundColor,
            padding: "0.2rem 0rem !important",
            minWidth: "30px",
          }}
        >
          <AttachMoneyOutlinedIcon fontSize="small" />
        </Icon>

        <Typography sx={{ fontSize: "16px", fontWeight, color: color }}>
          {title}
        </Typography>
      </ContainerTitleAndIcon>

      <Box
        style={{ width: "20%" }}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight, color }}>
          {value1 || value1 == 0
            ? formatterPeso.format(value1 as number)
            : "No hay valor disponible"}
        </Typography>
      </Box>
    </ContainerCardRowTotalHeader>
  );
};
