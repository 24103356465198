import { Box, Typography, theme } from "@enerbit/base";
import {
  Invoice,
  ServiceAgreement,
} from "../../../../../../models/typesShared";

export const DetailSettlementElectricitySupplyRight = ({
  invoice,
  serviceAgreement,
}: {
  invoice?: Invoice;
  serviceAgreement?: ServiceAgreement;
}) => {
  return (
    <Box>
      <Box sx={{ display: "flex", mr: "0.3rem" }}>
        {invoice?.details?.creg_subscriber?.voltage_level?.sui_code ? (
          <Typography
            color={theme.palette.primary.main}
            sx={{ fontWeight: "700", fontSize: "16px", mr: "0.3rem" }}
          >
            Nivel de tensión{" "}
            {invoice?.details?.creg_subscriber?.voltage_level?.sui_code}
          </Typography>
        ) : (
          <Box>No hay valor disponible</Box>
        )}
      </Box>
      <Box sx={{ display: "flex", mr: "0.3rem" }}>
        {serviceAgreement?.description ? (
          <Typography
            color={theme.palette.primary.main}
            sx={{ fontWeight: "700", fontSize: "16px", mr: "0.3rem" }}
          >
            {serviceAgreement?.description}
          </Typography>
        ) : (
          <Box>No hay valor disponible</Box>
        )}
      </Box>
    </Box>
  );
};
